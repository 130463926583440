import React from 'react';
import { CardGroup, Card, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import Loading from '../common/Loading.js';
import LoggedOutView from '../common/LoggedOutView.js';
import { Checkmark } from '../common/Checkmark.js';
import RenderRank from '../common/RankDisplay.js';

import SokobanGameBoard from './SokobanGameBoard.js';
import { SokobanLevels } from './SokobanLevel.js';


function SokobanBattlePlayer({player}) {
    const progressStyle = (p) => {
        if (p < 0) {
            return 'red';
        }
        return p > 0 ? 'green' : 'gray';
    };
    return (
        <Card className="battlePlayerCard">
            <Card.Body>
                <h5>
                    {player.rank ? RenderRank(player.rank) : null}
                    {player.name}
                </h5>
                {player.progress.map((p) => <Checkmark color={progressStyle(p)} />)}
            </Card.Body>
        </Card>
    );
}

function SokobanBattlePlayers({players}) {
    return (
        <CardGroup>
            {players.map((p) => <SokobanBattlePlayer player={p} />)}
        </CardGroup>
    );
}

export default function SokobanBattle() {
    let searchParams = useSearchParams()[0];
    let roomName = searchParams.get('room') ?? 'test';

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [roomState, setRoomState] = React.useState(null);
    const [requireLogin, setRequireLogin] = React.useState(false);
    const [isHost, setIsHost] = React.useState(false);
    const [started, setStarted] = React.useState(false);
    const [puzzleData, setPuzzleData] = React.useState(null);
    const [score, setScore] = React.useState(0);
    const [currPuzzle, setCurrPuzzle] = React.useState(0);
    const [timeRemaining, setTimeRemaining] = React.useState(0);
    const [ended, setEnded] = React.useState(false);

    const sockRef = React.useRef(null);
    const sendWS = (payload) => {
        // console.log(payload);
        sockRef.current.send(JSON.stringify(payload));
    };

    const joinRoom = () => {
        sendWS({'type': 'join'});
    };

    const handleStart = React.useCallback(() => {
        sendWS({'type': 'start'});
    }, []);

    const timerRef = React.useRef(null);
    const cleanUpTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
    };
    const startGame = () => {
        cleanUpTimer();
        timerRef.current = setInterval(() => {
            sendWS({'type': 'timer'});
        }, 200);
    };

    React.useEffect(() => {
        // wss in production and ws in development
        let sock = new WebSocket('wss://' + window.location.host + '/ws/sokoban/' + roomName + '/');
        sockRef.current = sock;
        sock.onmessage = function(e) {
            const data = JSON.parse(e.data);
            //console.log(data);
            const de = data.type;

            switch (de) {
            case 'game_end':
                setEnded(true);
                setStarted(false);
                cleanUpTimer();
                break;

            case 'game_start':
                setStarted(true);
                setEnded(false);

                SokobanLevels.uninstall();
                SokobanLevels.install(data.puzzles);
                setPuzzleData(SokobanLevels.getAllLevels());
                startGame();
                break;

            case 'timer':
                setTimeRemaining(data.time_remaining);
                if (data.time_remaining === 0) {
                    cleanUpTimer();
                }
                break;

            case 'room_update':
                setRoomState(data.roomState);
                setIsHost(data.host);
                setCurrPuzzle(data.curr);
                setScore(data.score);
                break;
            case 'require_login':
                setRequireLogin(true);
                break;
            default:
                break;
            }
        };
        sock.onclose = (e) => {
            console.log('You are disconnected from the server');
            cleanUpTimer();
        };
        sock.addEventListener('open', (e) => {
            console.log('connected');
            joinRoom();
            setIsLoaded(true);
        });
        return () => {
            console.log('Disconnecting...');
            sock.close();
        };
    }, []);

    const handleGameWon = React.useCallback((moves) => {
        // setCurrPuzzle(currPuzzle + 1);
        sendWS({'type': 'submit', 'moves': moves, 'level': currPuzzle});
    }, [currPuzzle]);

    const handleGameOver = React.useCallback((moves) => {
        // setCurrPuzzle(currPuzzle + 1);        
        sendWS({'type': 'submit', 'moves': moves, 'level': currPuzzle});
    }, [currPuzzle]);

    const handleRestartLevelEvent = () => {
        console.log('restart');
    };

    if (!isLoaded) {
        return <Loading />;
    }

    if (requireLogin) {
        return <LoggedOutView next="/sokoban/battle/" />;
    }

    if (!roomState) {
        return <div>Loading Room ...</div>;
    }

    let puzzle = puzzleData && Object.values(puzzleData)[currPuzzle]
    return (
        <div>
            {started && <div><h5>{timeRemaining}</h5></div>}
            {puzzle &&
                <SokobanGameBoard
                    key={'grid_' + puzzle.getName()}
                    gridData={puzzle}
                    onGameWon={handleGameWon}
                    onGameLost={handleGameOver}
                    onRestart={handleRestartLevelEvent}
                    showInfo={false}
                />
            }
            {isHost && !started && <Button onClick={handleStart}>Start</Button>}
            <SokobanBattlePlayers players={roomState.players} />
        </div>
    );
}
