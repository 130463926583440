import '../common/Navbar.css';

import React from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { CalendarEvent, Cart3, DoorOpen, Facebook, Google, Grid3x3Gap, InfoSquare, PersonLock, Puzzle, ShieldLock } from 'react-bootstrap-icons';

import { fetchLoginStatus } from './SecurityUtils.js';
import Loading from './Loading.js';

function AccountNavItems() {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    React.useEffect(() => {
        fetchLoginStatus((loggedIn, name) => {
            setIsLoaded(true);
            setIsLoggedIn(loggedIn);
        });
    }, []);

    if (!isLoaded) {
        return <Loading />;
    }

    const next = window.location.pathname;
    if (!isLoggedIn) {
        return (
            <>
		{/*
                <Nav.Link href={'/oauth/login/facebook/?next=' + next}>
                    <Facebook /> Log In with Facebook
                </Nav.Link>
		 */}
                <Nav.Link href={'/oauth/login/google-oauth2/?next=' + next}>
                    <Google /> Log In with Google
                </Nav.Link>
                <Nav.Link href={'/account/?next=' + next}>
                    <PersonLock /> Sign In or Sign Up
                </Nav.Link>
            </>
        );
        
    }

    return (
        <>
            <Nav.Link href={'/logout/?next=' + next}>
                <DoorOpen /> Log Out
            </Nav.Link>
        </>
    );
}

export default function CommonNavbar(props) {
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleToggle = () => setShow(!show);

    return (
        <Navbar className="navbar" variant="dark" bg="dark">
            <Container>
                <Navbar.Brand href="#" onClick={handleToggle}>
                    <span className="navbar-toggler-icon" />
                    {' '}
                    {props.title}
                </Navbar.Brand>

                {/* custom nav item from specific pages */}
                {props.children}

                <Navbar.Offcanvas 
                    className="navbar-dark bg-dark text-white"
                    show={show}
                    onHide={handleClose}
                    placement="start">
                    <Offcanvas.Header closeButton />
                    <Offcanvas.Body>
                        <Nav className="flex-column">
                            <Nav.Link href="/sokoban/">
                                <Grid3x3Gap /> Sokoban
                            </Nav.Link>

                            <Nav.Link href="/puzzle/">
                                <Puzzle /> Corgillogical
                            </Nav.Link>

                            <Nav.Link href="/shop/">
                                <Cart3 /> Shop
                            </Nav.Link>

                            <Nav.Link href="/shop/?tab=events">
                                <CalendarEvent /> Event
                            </Nav.Link>

                            <Nav.Link href="/">
                                <InfoSquare /> About
                            </Nav.Link>

                            <hr />

                            <AccountNavItems />

                            <hr />

                            <Nav.Link href="/privacy/">
                                <ShieldLock /> Privacy Policy
                            </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
          </Container>
        </Navbar>
    );
}
