import React from "react";
import { Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSearchParams } from 'react-router-dom';

import CommonNavbar from '../common/CommonNavbar.js';

function AdminLinkContainer() {
    let [searchParams] = useSearchParams();
    let event = searchParams.get('event_id') ?? '';
    
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEventAdmin, setIsEventAdmin] = React.useState(false);

    React.useEffect(() => {
	if (!event) {
	    setIsLoaded(true);
	    return;
	}
	
	setIsLoaded(false);
	fetch('/event/admin_status/?event_id=' + event)
            .then(res => res.json())
            .then(
		(payload) => {
		    setIsEventAdmin(payload.isAdmin);
  		    setIsLoaded(true);
		},
		(error) => {
  		    setIsLoaded(true);
		}
            )
    }, []);

    if (!event.trim()) {
	return null;
    }
    
    if (!isLoaded || !isEventAdmin) {
        return null;
    }
    
    return (
        <>
            <LinkContainer to={'/event/admin/?event_id=' + event}>
                <Nav.Link>Admin</Nav.Link>
            </LinkContainer>
            <LinkContainer to={'/event/scanner/?event_id=' + event}>
                <Nav.Link>Admission</Nav.Link>
            </LinkContainer>
        </>
    );
}

function ShowLinkContainer() {
    let [searchParams] = useSearchParams();
    let event = searchParams.get('event_id') ?? '';
    
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [show, setShow] = React.useState(null);

    React.useEffect(() => {
	fetch('/show/status/?event_id=' + event)
            .then(res => res.json())
            .then(
		(res) => {
		    setShow(res.show);
  		    setIsLoaded(true);
		},
		(error) => {
  		    setIsLoaded(true);
		}
            )
    }, []);

    if (!isLoaded || !show) {
        return null;
    }
    
    return (
        <>
            <LinkContainer to={'/show/?s=' + show}>
                <Nav.Link>Singer Portal</Nav.Link>
            </LinkContainer>
        </>
    );
}

export default function EventHeader() {
    return (
        <>
            <CommonNavbar className="sokobanNavbar" title="Event">
                <Nav>
		    <ShowLinkContainer />
		    <AdminLinkContainer />
                </Nav>
            </CommonNavbar>
        </>
    );
}
