import './email.css';

import React from 'react';
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Routes, Route, useSearchParams } from 'react-router-dom';

import Loading from './common/Loading.js';
import CommonNavbar from './common/CommonNavbar.js';
import { postWithToken, getWithData } from './common/SecurityUtils.js';

export default function EmailSubscriptionManager() {
    let [searchParams] = useSearchParams();
    // let email = searchParams.get('e');
    let token = searchParams.get('t');

    return (
        <div>
            <CommonNavbar title="Corgillogical" />
            <div className="AppBody">
                <Container className="AppContainer">
                    <ManageEmailView token={token} />
                </Container>
            </div>
        </div>
    );
}

function ManageEmailView({token}) {
    const [email, setEmail] = React.useState('');
    const [activeEmail, setActiveEmail] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [preferences, setPreferences] = React.useState({});
    
    React.useEffect(() => {
        setIsLoaded(false);

        const data = {
            t: token,
        };

        getWithData('/email/validate/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    if ('error' in res) {
                        if ('show_error' in res) {
                            setErrorMessage(res['error']);
                        }
                        setIsLoaded(true);
                        return
                    }
                    if (!'email' in res) {
                        return
                    }
                    setEmail(res.email);
                    setActiveEmail(res.email);
                    setPreferences(res.preferences);
                    setIsLoaded(true);
                }
            )
    }, []);
    
    const handleSubmit = React.useCallback((e) => {
        setIsLoaded(false);
        const data = {
            'e': email,
            't': token,
        };
        
        postWithToken('/email/request/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setIsLoaded(true);
                    if ('error' in res) {
                        setMessage(null);
                        setErrorMessage(res.error);
                        return;
                    }

                    setMessage(res.message);
                    setErrorMessage(null);
                }
            )
    }, [email]);
    
    const handleSave = React.useCallback((unsub = false) => {
        const form = document.getElementsByClassName('notificationOptions')[0];
        let options = {};
        Array.from(form.getElementsByClassName('form-switch')).forEach(
            (elem) => {
                let checkbox = elem.getElementsByTagName('input')[0];
                options[checkbox.name] = checkbox.checked;
            },
        )
        
        setIsLoaded(false);
        
        const data = {
            'e': activeEmail,
            't': token,
            'options': options,
            'unsub': unsub,
        };
        
        postWithToken('/email/edit/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    if ('error' in res) {
                        setMessage(null);
                        setErrorMessage(res.error);
                        setIsLoaded(true);
                        return;
                    }
                    
                    setPreferences(res.preferences);
                    setMessage(res.message);
                    setErrorMessage(null);

                    if (res.unsub) {
                        setActiveEmail(null);
                        setEmail(null);
                    }
                    
                    setIsLoaded(true);
                }
            )
        
        
    }, [activeEmail, token]);

    const renderEditForm = () => {
        if (!isLoaded) {
            return <Loading />;
        }

        return (
            <Card.Text>
                {message && <Alert variant="success">{message}</Alert>}
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <Form>
                    <InputGroup size="sm" className="mb-3" controlId="formEmail">
                        <Form.Control
                            required
                            disabled={activeEmail}
                            value={email}
                            type="email"
                            placeholder="Email Address"
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Button
                            disabled={activeEmail}
                            variant="primary"
                            onClick={(e) => handleSubmit(e)}>
                            Manage
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => window.location.href='/email/'}>
                            Clear
                        </Button>
                    </InputGroup>
                </Form>
                <Form className="notificationOptions" disabled={!activeEmail}>
                    <fieldset disabled={!activeEmail}>
                        <Form.Check
                            type="switch"
                            name="music"
                            label="Music Shows"
                            defaultChecked={preferences.music}
                        />
                        <Form.Check
                            className="secondLevel"
                            type="switch"
                            name="singer"
                            label="Singer Productions"
                            defaultChecked={preferences.singer}
                        />
                        <Form.Check
                            className="secondLevel"
                            type="switch"
                            name="band"
                            label="Live Band Performances"
                            defaultChecked={preferences.band}
                        />                            
                        <Form.Check
                            type="switch"
                            name="puzzles"
                            label="Puzzles & Brain Games"
                            defaultChecked={preferences.puzzles}
                        />
                        <Form.Check
                            className="secondLevel"
                            type="switch"
                            name="hunts"
                            label="Puzzle Hunts (Bay Area)"
                            defaultChecked={preferences.hunts}
                        />
                        <Form.Check
                            className="secondLevel"
                            type="switch"
                            name="questions"
                            label="Online Puzzles"
                            defaultChecked={preferences.questions}

                        />                            
                        <InputGroup size="sm" className="mb-3" controlId="actionButtons">
                            <Button
                                variant="primary"
                                onClick={(e) => handleSave(false)}>
                                Save Preferences
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={(e) => handleSave(true)}>
                                Unsubscribe
                            </Button>
                        </InputGroup>
                    </fieldset>
                </Form>
            </Card.Text>
        );
    };
    
    return (
        <Card>
            <Card.Header>Manage Email Notifications</Card.Header>
            <Card.Body>
                <Card.Text>
                    If you love music or puzzles, you can opt into receiving any upcoming
                    music performances/productions or new puzzle releases. You can make fine-grained
                    selections to cater to your interest and can unsubscribe anytime you{"'"}d like.
                </Card.Text>
                <Card.Text>
                    In order to prove that you have access to the email address, a link to manage your
                    settings will be sent to your email address upon request.
                </Card.Text>
                <Card.Text>
                    <Alert>            
                        If you use a Microsoft email provider (such as hotmail.com or live.com),
                        be sure to add cocoa@corgillogical.com to your Safe Senders, as they have
                        aggressive IP block ranges which includes the email service we are using.
                    </Alert>
                </Card.Text>
                {renderEditForm()}
            </Card.Body>
        </Card>
    );
}
