import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-bootstrap';

import { getCSRFToken } from '../common/SecurityUtils.js';


const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });


export default function PayPalPurchaseButton({
    cart,
    coupon = null,
    rData,
    successCallback,
    errorCallback,
}) {
    const captureOrder = (oid) =>
	  fetch("/paypal_api/capture-paypal-order/", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': getCSRFToken(),
              },
              body: JSON.stringify({
                  orderID: oid,
              })
          });
    
    const createOrder = (data) => {
        // Order is created on the server and the order id is returned
        return fetch("/paypal_api/create-paypal-order/", {
            method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCSRFToken(),
                },
                // use the "body" param to optionally pass additional order information
                // like product skus and quantities
                body: JSON.stringify({
                    reservationData: rData,
                    coupon: coupon,
                    cart: cart,
                }),
            })
        .then((response) => response.json())
        .then((res) => {
            if (res.id) {
		// Handle the case where order is free
		if (res.internal_process === 'free') {
		    return captureOrder(res.id)
			.then((response) => response.json())
			.then(
			    (res) => successCallback(res),
			    (error) => errorCallback(error),
			);
		}
                return res.id;
            }
            
            // no order id returned. treat it as an error.
            errorCallback(res);
            return null;
        });
    };

    const onApprove = (data) => {
        // failed to create an order in the previous step
        if (!data.orderID) {
            return;
        }

        // Order is captured on the server
        return captureOrder(data.orderID)
            .then((response) => response.json())
            .then(
		(res) => successCallback(res),
		(error) => errorCallback(error),
            );
    };

    return (
        <PayPalButton
            createOrder={(data, actions) => createOrder(data)}
            onApprove={(data, actions) => onApprove(data)}
        />
    );
}
