import React from 'react';
import { Container } from 'react-bootstrap';
import {
    Card,
    Nav,
} from 'react-bootstrap';

import { useNavigate, useSearchParams } from 'react-router-dom';
import SiteURL from '../common/SiteURL.js';
import { fetchLoginStatus, postWithToken } from '../common/SecurityUtils.js';

import AccountLogin from './AccountLogin.js';
import AccountSignup from './AccountSignup.js';
import AccountPassword from './AccountPassword.js';

export default function AccountManagement({}) {
    let [searchParams] = useSearchParams();
    let next = searchParams.get('next');
    let token = searchParams.get('t');

    const [isLoaded, setIsLoaded] = React.useState(true);
    let defaultTab = window.location.hash ? window.location.hash : '#login';
    if (token) {
	defaultTab = '#password';
    }
    const [tab, setTab] = React.useState(defaultTab);
    const [loggedIn, setIsLoggedIn] = React.useState(false);

    // Load logged in data once
    React.useEffect(() => {
        setIsLoaded(false);
        fetchLoginStatus((loggedIn) => {
            setIsLoggedIn(loggedIn);
	    setIsLoaded(true);
        });
    }, []);

    if (loggedIn) {
	return (
	    <Card>
		<Card.Body>
		    You are currently logged
		    in. <a href="/logout/?next=/">click here</a> to log out.
		</Card.Body>
	    </Card>
	);
    }
    
    const renderBody = () => {
	switch (tab) {
	case '#login':
	    return <AccountLogin next={next} />;
	case '#signup':
	    return <AccountSignup />;
	case '#password':
	    return <AccountPassword token={token} />;
	}
	return null;
    };
    
    return (
	<Card>
	    <Card.Header>
		<Nav
		    activeKey={tab}
		    variant="tabs"
		    defaultActiveKey="#login"
		    onSelect={(e) => setTab(e)}>
		    <Nav.Item>
			<Nav.Link href="#login">Sign In</Nav.Link>
		    </Nav.Item>
		    <Nav.Item>
			<Nav.Link href="#signup">Sign Up</Nav.Link>
		    </Nav.Item>
		    {
			token &&
			    <Nav.Item>
				<Nav.Link href="#password">Create Password</Nav.Link>
			    </Nav.Item>
		    }
		</Nav>
	    </Card.Header>
	    <Card.Body>
		{renderBody()}
	    </Card.Body>
	</Card>
    );
}
