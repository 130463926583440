import React from 'react';
import { useCallback, useState, useEffect, useRef } from 'react';

import './Band.css';


const SETLIST = [
    'palefire',
];

const BPM = [
    120,
];

const REF_BPM = 120;

export default function BandPaleFire() {
    const videoRef = useRef();
    const [frame, setFrame] = useState(0);
    const [transition, setTransition] = useState(true);

    const handleUserKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        if (keyCode === 32) {
            let nframe = !transition ? (frame + 1) % SETLIST.length : frame;
            setFrame(nframe);
            if (transition) {
                let video = videoRef.current;
                video.pause();
                video.currentTime = 0;
                video.play();
            }
            
            // setFolder(SETLIST[nframe]);
            setTransition(!transition)
        } else if (keyCode === 64 + 16) {
            let video = videoRef.current;
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        } else if (keyCode >= 65 && keyCode <= 90) {
            let nframe = (frame + SETLIST.length - 1) % SETLIST.length;
            setFrame(nframe);
            //setFolder(SETLIST[nframe]);
        }
    }, [frame, transition]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);
    
    
    const folder = SETLIST[frame];
    const bpm = BPM[frame];
    const speed = bpm / REF_BPM;

    let vid_src = '/static/videos/' + folder + '.mp4';

    let setSpeed = useCallback(() => {
        videoRef.current.playbackRate = speed;
    }, [videoRef, speed]);

    return (
        <div className="ImgContainer">
            <div className={'logoBackground ' + (transition ? 'transition' : 'show')}>
                <img
                    className="palefirelogo logo"
                    src="/static/palefire.jpeg"
                    alt="PaleFire Logo"
                />
            </div>
            <video
                ref={videoRef}
                className="VideoTag"
                onCanPlay={() => setSpeed()}
                autoPlay
                loop
                muted
                key={vid_src}>
                <source src={vid_src} type='video/mp4'/>
            </video>
        </div>
    );
}
