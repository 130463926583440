import './EventTicket.css';

import { Container } from 'react-bootstrap';
import {Routes, Route, useSearchParams} from 'react-router-dom';

import NotFound from '../home/NotFound.js';
import CommonNavbar from '../common/CommonNavbar.js';

import EventHeader from './EventHeader.js';
import EventHosting from './EventHosting.js';
import EventView from './EventView.js';
import EventTicketAdmin from './EventTicketAdmin.js';
import EventTicketView from './EventTicketView.js';
import EventPosterGenerator from './EventPosterGenerator.js';
import EventTicketScanner from './EventTicketScanner.js';

export default function EventAppRouter() {
    let [searchParams] = useSearchParams();
    let event = searchParams.get('event_id');
    let h = searchParams.get('h');
    let t = searchParams.get('t');
    
    return (
        <div>
            <EventHeader />

            <div className="AppBody">
            <Container className="AppContainer">

                <Routes>
                    <Route path='*' element={<NotFound />} />

                    <Route
                        index 
                        element={
                            <EventView eventID={event} tracker={t} />
                        }
                    />

                    <Route path="admin" element={<EventTicketAdmin eventID={event} />} />
                    <Route path="host" element={<EventHosting />} />
                    <Route path="poster" element={<EventPosterGenerator eventID={event} />} />
                    <Route path="scanner" element={<EventTicketScanner eventID={event} />} />

                    <Route path="ticket" element={<EventTicketView eventID={event} ticketH={h} />} />
                </Routes>

            </Container>
            </div>
        </div>
    );
}
