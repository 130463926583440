import React from 'react';
import { Container } from 'react-bootstrap';
import {
    Alert,
    Button,
    Card,
    Form,
    InputGroup,
} from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import SiteURL from '../common/SiteURL.js';
import { getWithData, postWithToken } from '../common/SecurityUtils.js';

export default function AccountPassword({token}) {
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [message, setMessage] = React.useState('');
    const [success, setSuccess] = React.useState(null);
    const [validated, setValidated] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const pwRef = React.useRef();
    const cpwRef = React.useRef();
    
    const navigate = useNavigate();

    // Load logged in data once
    React.useEffect(() => {
        setIsLoaded(false);
        getWithData('/account/staging/', {token: token})
	    .then(res => res.json())
	    .then(
		(res) => {
		    setMessage(res.msg);
		    setSuccess(res.success);
		    if (res.success) {
			setUsername(res.username);
			setEmail(res.email);
		    }
		},
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
	    )
    }, []);

    
    const handleSubmit = React.useCallback((e) => {
	if (password !== confirmPassword) {
	    setMessage('The passwords don\'t match');
	    setSuccess(false);
	    setIsLoaded(true);

	    pwRef.current.setCustomValidity('Mismatched password');
	    cpwRef.current.setCustomValidity('Mismatched password');
	    setValidated(true);

            e.preventDefault();
            e.stopPropagation();
	    return;
	}

	setValidated(true);
        setIsLoaded(false);
        const data = {
	    'token': token,
	    'password': password,
        };

        postWithToken('/account/staging/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
		    setSuccess(res.success);
		    if (res.bad_password) {
			pwRef.current.setCustomValidity('Bad Password')
		    }
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
	    );
    }, [token, password, confirmPassword]);

    const onPWChange = (e) => {
	pwRef.current.setCustomValidity('');
	cpwRef.current.setCustomValidity('');
	setPassword(e.target.value);
	setValidated(false);
    };

    const onCPWChange = (e) => {
	pwRef.current.setCustomValidity('');
	cpwRef.current.setCustomValidity('');
	setConfirmPassword(e.target.value);
	setValidated(false);
    };

    return (
	<div>
	    {message &&
	     <Alert variant={success ? 'success' : 'danger'}>
		 {message}
	     </Alert>}		
	    <Form noValidate validated={validated} disabled={!isLoaded}>
		<InputGroup className="mb-3" controlId="formUsername">
	            <InputGroup.Text>Username</InputGroup.Text>
	            <Form.Control
			readonly
			type="text"
			value={username}
		    />
		</InputGroup>
		<InputGroup className="mb-3" controlId="formEmail">
	            <InputGroup.Text>Email</InputGroup.Text>
	            <Form.Control
			readonly
			type="email"
			value={email}
		    />
		</InputGroup>
		<InputGroup className="mb-3" controlId="formPassword">
	            <InputGroup.Text>Password</InputGroup.Text>
	            <Form.Control
			ref={pwRef}
			type="password"
			value={password}
			onChange={onPWChange}
		    />
		</InputGroup>
		<InputGroup className="mb-3" controlId="formConfirmPassword">
	            <InputGroup.Text>Confirm Password</InputGroup.Text>
	            <Form.Control
			ref={cpwRef}
			type="password"
			value={confirmPassword}
			onChange={onCPWChange}
		    />
		</InputGroup>
		<Button
		    variant="primary"
		    onClick={(e) => handleSubmit(e)}>
		    Complete Account Creation
		</Button>
	    </Form>
	</div>
    );
}
