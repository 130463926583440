import { Badge } from 'react-bootstrap';

export function ShowPerformanceStatus({status}) {
    switch (status) {
    case 'PE': return <Badge bg="secondary">Pending</Badge>;
    case 'RD': return <Badge bg="primary">Ready</Badge>;
    case 'CN': return <Badge bg="success">Concluded</Badge>;
    case 'CC': return <Badge bg="dark">Canceled</Badge>;
    default: return <Badge bg="dark">Unknown</Badge>;
    }
}

export function ShowProgramStatus({status}) {
    switch (status) {
    case 'PE': return <Badge bg="secondary">Pending</Badge>;
    case 'RV': return <Badge bg="light" text="dark">In Review</Badge>;
    case 'CF': return <Badge bg="success">Confirmed</Badge>;
    default: return <Badge bg="dark">Unknown</Badge>;
    }
}

export function ShowProgramStyle({style}) {
    switch (style) {
    case 'SO': return <Badge bg="primary">Solo</Badge>;
    case 'DU': return <Badge bg="light" text="dark">Duet</Badge>;
    case 'BA': return <Badge bg="warning">Band</Badge>;
    case 'IN': return <Badge bg="secondary">Instrumental</Badge>;
    case 'OT': return <Badge bg="info">Other</Badge>;
    default: return <Badge bg="dark">Unknown</Badge>;
    }
}
