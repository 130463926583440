import { Facebook, Google, PersonLock } from 'react-bootstrap-icons';

export default function LoggedOutView({next, children}) {
    const renderText = () => {
	if (children) {
	    return children;
	}

	return (
	    <>
		You are not currently logged in.
		<br />
		<br />
		Log in to <b>get extra puzzles</b>, <b>track progress</b> and <b>challenge the leaderboard</b>!
	    </>
	);
    };
    
    return (
	<div>
	    <div>
		{renderText()}
	    </div>
            <br />
	    <div>
		You can log in with
	    </div>
	    <br />
	    <div>
		{/*
		   <a href={'/oauth/login/facebook/?next=' + next}>
                   <Facebook /> Login with Facebook
		   </a>
		   <br />
		 */}
		<a href={'/oauth/login/google-oauth2/?next=' + next}>
                    <Google /> Login with Google
		</a>
	    </div>
            <div>
		or 
	    </div>
	    <div>
	        <a href={'/account/?next=' + next}>
		    <PersonLock /> Sign In or Sign Up
	        </a>
	    </div>
        </div>
    );    
}
