import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import ShowProgram from './ShowProgram.js';

export default function ShowPerformanceList({programs}) {
    const renderPerformances = () => {
	if (!programs || programs.length === 0) {
	    return <div>You currently have no performances</div>;
	}

	return (
	    <Row xs={1} md={2}>
                {programs.map(
                    x => <Col><ShowProgram program={x} /></Col>
                )}
            </Row>
	);
    };
    
    return (
	<Card>
	    <Card.Header>
		Your Performances
	    </Card.Header>
	    <Card.Body>	
		{renderPerformances()}
	    </Card.Body>
	</Card>
    );
}
