import React from 'react';
import {
    Alert,
    Button,
    Card,
    Form,
    InputGroup
} from 'react-bootstrap';

import { validateEmail } from '../common/Utils.js';
import Loading from '../common/Loading.js';
import { postWithToken } from '../common/SecurityUtils.js';

export default function ShowPerformerCard({onEdit}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [validated, setValidated] = React.useState(false);
    
    const [name, setName] = React.useState(null);
    const [contact, setContact] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    
    // Fetch once
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/show/fetch_performer/')
            .then(res => res.json())
            .then(
                (payload) => {
                    setName(payload.name);
		    setEmail(payload.email);
		    setContact(payload.contact);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                }
            )
    }, []);

    const handleEdit = React.useCallback((e) => {
        const form = e.currentTarget;
	setValidated(false);
        let isValid = form.checkValidity();
	if (isValid && !validateEmail(email)) {
            setMessage('Please enter a valid email');
	    isValid = false;
	}
	
        setValidated(true);
        if (!isValid) {
	    e.preventDefault();
            e.stopPropagation();
            return;
        }
	
        setIsLoaded(false);
        const data = {
            'name': name,
            'email': email.trim(),
            'contact': contact,
        };

        postWithToken('/show/edit_performer/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
                    setIsLoaded(true);
		    if (onEdit) {
			onEdit();
		    }
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
            );
    }, [name, email, contact, onEdit]);
    
    if (!isLoaded) {
        return <Loading />;
    }

    return (
	<Card>
	    <Card.Header>
		Performer Contact Info
	    </Card.Header>
	    <Card.Body>
		{message && <Alert>{message}</Alert>}
		<Form noValidate validated={validated}>
		    <InputGroup size="sm" className="mb-3" controlId="formName">
			<InputGroup.Text>Name (public)</InputGroup.Text>
			<Form.Control
			    required
			    value={name}
			    type="text"
			    placeholder="Your Name"
			    onChange={e => setName(e.target.value)}
			/>
		    </InputGroup>
		    <InputGroup size="sm" controlId="formEmail">
			<InputGroup.Text>Email</InputGroup.Text>
			<Form.Control
			    required
			    value={email}
			    type="email"
			    placeholder="Enter email"
			    onChange={e => setEmail(e.target.value)}
			/>
		    </InputGroup>
		    <Form.Text className="mb-3" muted>
			Only shared with event coordinator
		    </Form.Text>
		    <InputGroup size="sm" controlId="formContact">
			<InputGroup.Text>Wechat</InputGroup.Text>
			<Form.Control
			    required
			    value={contact}
			    type="text"
			    placeholder="Enter Other Contact (such as wechat handle)"
			    onChange={e => setContact(e.target.value)}
			/>
		    </InputGroup>
		    <Form.Text className="mb-3" muted>
			Only shared with event coordinator
		    </Form.Text>
		</Form>
	    </Card.Body>
	    <Card.Footer>
                <Button
		    variant="primary"
		    onClick={(e) => handleEdit(e)}>
		    Save Contact Info
                </Button>	
	    </Card.Footer>
	</Card>
    );
}
