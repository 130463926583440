import { Container } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';

import NotFound from '../home/NotFound.js';
import CommonNavbar from '../common/CommonNavbar.js';

import AccountManagement from './AccountManagement.js';

export default function EventAppRouter() {
    return (
        <div>
            <CommonNavbar className="sokobanNavbar" title="Event">
	    </CommonNavbar>
            <div className="AppBody">
            <Container className="AppContainer" style={{ maxWidth: "768px" }}>
                <Routes>
                    <Route path='*' element={<NotFound />} />
                    <Route
                        index 
                        element={
                            <AccountManagement />
                        }
                    />
                    <Route path="ticket" element={<div>test</div>} />
                </Routes>
            </Container>
            </div>
        </div>
    );
}
