import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import SiteURL from '../common/SiteURL.js';
import { ShowProgramStatus, ShowProgramStyle } from './ShowProgramComponents.js';

function title(t) {
    if (t) {
	return t;
    }
    return '<Unnamed Program>';
}

export function ShowProgramCompact({program}) {
    const renderCopiable = (str) => {
	if (!str) {
	    return null
	}
	const copyStr = (e) => {
	    navigator.clipboard.writeText(str);
	};
	return <a href="#" onClick={copyStr}>{str}</a>;
    };
    
    return (
	<div className="programCompact">
	    <div>
		<b>{title(program.name.trim())}</b>
		<span className="mx-1">
		    <ShowProgramStyle style={program.style} />
		</span>
	    </div>
	    <div className="programContact">
		<small className="text-muted">{program.performer}</small>
		{renderCopiable(program.performer_email)}
		{renderCopiable(program.performer_contact)}
	    </div>
	    <div className="text-muted programNotes">
		{program.notes}
	    </div>
	</div>
    );
}

export default function ShowProgram({program}) {
    let url = new SiteURL().show().setParams(
	{
	    p: program.program_id,
	}
    ).getRelative();
    
    return (
	<Card className="mb-1">
	    <Card.Body>
		<Card.Title>
		    {title(program.name.trim())} @ {program.performance.event_name}
		</Card.Title>
		<Row>
		    <Col>Status</Col>
		    <Col><ShowProgramStatus status={program.status} /></Col>
		</Row>
		<Row>
		    <Col>Name</Col>
		    <Col>{program.name}</Col>
		</Row>
		<Row>
		    <Col>Order</Col>
		    <Col>{program.order ? program.order : 'Not scheduled'}</Col>
		</Row>
		<Row>
		    <Col>Style</Col>
		    <Col><ShowProgramStyle style={program.style} /></Col>
		</Row>
	    </Card.Body>
	    <Card.Footer>
		<Button href={url}>View and Edit</Button>
	    </Card.Footer>
	</Card>
    );
}
