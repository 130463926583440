export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function getCSRFToken() {
    return getCookie('csrftoken');
}

export function postWithToken(url, data) {
    return fetch(url, {
        credentials: 'include',
        method: 'POST',
        mode: 'same-origin',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCSRFToken(),
        },
        body: JSON.stringify(data),
    });
}

export function getWithData(url, data) {
    let st = Object.keys(data).map(x => x + '=' + data[x]).join('&');
    return fetch(url + '?' + st);
}

export function fetchLoginStatus(resultCallback) {
    fetch('/login_status/')
        .then(res => res.json())
        .then(
              (payload) => {
                  resultCallback(payload.loggedIn, payload.name, payload.email);
              },
              (error) => {
                  resultCallback(null, null, null);
              }
        )
}
