import './Band.css';

export default function BandChestnuters() {
    return (
        <div className="ImgContainer">
            <div className={'logoBackground show'}>
                <img
                    className="logo chestnutlogo"
                    src="/static/chestnuters.png"
                    alt="Chestnuters Logo"
                />
            </div>
        </div>
    );
}
