import React from "react";
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSearchParams } from 'react-router-dom';

import CommonNavbar from '../common/CommonNavbar.js';

function AdminLinkContainer() {
    let [searchParams] = useSearchParams();
    let event = searchParams.get('event_id') ?? '';
    
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEventAdmin, setIsEventAdmin] = React.useState(false);

    React.useEffect(() => {
	if (!event) {
  	    setIsLoaded(true);
	    return;
	}
	fetch('/event/admin_status/?event_id=' + event)
            .then(res => res.json())
            .then(
		(payload) => {
		    setIsEventAdmin(payload.isAdmin);
  		    setIsLoaded(true);
		},
		(error) => {
  		    setIsLoaded(true);
		}
            )
    }, [event]);

    if (!event.trim()) {
	return null;
    }
    
    if (!isLoaded || !isEventAdmin) {
        return null;
    }
    
    return (
        <>
            <LinkContainer to={'/event/admin/?event_id=' + event}>
                <Nav.Link>Admin</Nav.Link>
            </LinkContainer>
            <LinkContainer to={'/event/scanner/?event_id=' + event}>
                <Nav.Link>Admission</Nav.Link>
            </LinkContainer>
        </>
    );
}

export default function EventHeader() {
    return (
        <>
            <CommonNavbar className="sokobanNavbar" title="Event">
                <Nav>
		    <AdminLinkContainer />
                </Nav>
            </CommonNavbar>
        </>
    );
}
