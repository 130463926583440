import React from 'react';
import { Card } from 'react-bootstrap';

import SiteURL from '../common/SiteURL.js';
import ConfigurableMarkup from '../common/ConfigurableMarkup.js';

import { ShowPerformanceStatus } from './ShowProgramComponents.js'

export default function ShowPerformanceSummaryCard({show}) {
    const renderInstructions = () => {
	if (!show.instructions) {
	    return null;
	}
	return new ConfigurableMarkup().fromXML(
	    new DOMParser().parseFromString(show.instructions, "text/xml").firstChild
	);
    };

    let event_url = new SiteURL()
        .event()
        .setParams({event_id: show.event_id})
        .getRelative();
    
    return (
	<Card className="mb-3">
	    <Card.Header>		
		<Card.Title>
		    <a href={event_url} target="_blank">
			{show.event_name}
		    </a>
		</Card.Title>
		<div className="headerCorner">
		    <ShowPerformanceStatus status={show.status} />
		</div>
	    </Card.Header>
	    <Card.Body>
		<Card.Text>
		    {renderInstructions()}
		</Card.Text>
	    </Card.Body>
	</Card>
    );
}
