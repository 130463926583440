import Latex from 'react-latex';
import {
    Card,
    CardGroup,
    Col,
    Container,
    Row,
} from 'react-bootstrap';

import { classes } from '../common/Utils.js';

function CrosswordCell({cell}) {
    const cls = classes({
        puzzleTableCell: true,
        fill: cell === null,
    });
    return (
        <div className={cls}>
            <div>{cell > 0 ? cell : ''}</div>
        </div>
    );
}

function CrosswordRow({row}) {
    return (
        <div className="puzzleTableRow">
            {row.map(x => <CrosswordCell cell={x} />)}
        </div>
    );
}

function CrosswordGrid({rows, ...attr}) {
    return (
        <div className="puzzleTable puzzleCrosswordGrid" {...attr}>
            {rows.map(r => <CrosswordRow row={r} />)}
        </div>
    );
}

function crosswordCellMarkup(cell) {
    const cls = classes({
        puzzleTableCell: true,
        fill: cell === null,
    });

    const closing = cell === null ? ' style="background-color: black">' : '>';

    return (
        '<td' + closing + (cell > 0 ? cell : '') + '</td>'
    );
}

function crosswordRowMarkup(row) {
    return (
        '<tr>'
        + row.map(x => crosswordCellMarkup(x)).join('\n')
        + '</tr>'
    );
}

function crosswordGridMarkup(rows) {
    return (
        '<table>'
        + rows.map(r => crosswordRowMarkup(r)).join('\n')
        + '</table>'
    );
}

export default class ConfigurableMarkup {
    constructor() {
	this.customMapper = null;
    }

    /**
     * A lambda to map any cases that's not handled:
     *   (tag, attr, inner) => ?element
     */
    setMapper(mapper) {
	this.customMapper = mapper;
	return this;
    }

    /**
     * Private helper - don't call this directly. Call one of the functions below
     * instead.
     */
    tagToReactBuilder(tag, attr, inner) {
        switch (tag) {
        case 'cardgroup': return <CardGroup {...attr}>{inner}</CardGroup>;
        case 'card': return <Card {...attr}>{inner}</Card>;
        case 'small': return <small {...attr}>{inner}</small>;
        case 'cardheader': return <Card.Header {...attr}>{inner}</Card.Header>;
        case 'cardbody': return <Card.Body {...attr}>{inner}</Card.Body>;
        case 'cardimage': return <Card.Img {...attr} />;
        case 'cardtext': return <Card.Text {...attr}>{inner}</Card.Text>;
        case 'container': return <Container {...attr}>{inner}</Container>;
        case 'bsrow': return <Row {...attr}>{inner}</Row>;
        case 'bscol': return <Col {...attr}>{inner}</Col>;
        case 'h1': return <h1>{inner}</h1>;
        case 'link':
            return <a {...attr}>{inner}</a>;
        case 'b':
            return <b {...attr}>{inner}</b>;
        case 'br':
            return <br />;
        case 'flex':
            return <div className="puzzleFlex" {...attr}>{inner}</div>;
        case 'div':
            return <div {...attr}>{inner}</div>;
        case 'table':
            return <div className="puzzleTable" {...attr}>{inner}</div>;
        case 'row':
            return <div className="puzzleTableRow" {...attr}>{inner}</div>;
        case 'cell':
            return <div className="puzzleTableCell" {...attr}>{inner}</div>;
        case 'block':
            return <div className="puzzleTableCell block" {...attr}>{inner}</div>;
        case 'img':
            return <img className="puzzleImage" alt="puzzle content" {...attr} />;
        case 'space':
    	return <>{"\u00A0"}</>;
        case 'span':
            return <span {...attr}>{inner}</span>;
        case 'latex':
            return <Latex {...attr}>{inner}</Latex>;
        case 'latex2':
            return <Latex {...attr}>What is $(3\times 4) \div (5-3)$</Latex>;
        case 'li':
            return <li {...attr}>{inner}</li>;
        case 'ol':
            return (
                <div className="puzzleClueList">
                    <ol {...attr}>{inner}</ol>
                </div>
            );
        case 'ul':
            return (
                <div className="puzzleClueList">
                    <ul {...attr}>{inner}</ul>
                </div>
            );
        default:
            return null;
        }
    }
    
    fromXML(node) {
        if (!node) {
            return <div />;
        }

        // Handle text nodes
        if (node.nodeType === node.TEXT_NODE) {
    	return <>{node.textContent}</>;
        }

        // Here we assume we don't get attribute nodes (and only handle elements)
        
        let inner = [];
        if (node.hasChildNodes()) {
            for (let child of node.childNodes) {
                inner.push(this.fromXML(child));
            }
        } else {
            inner = node.innerHTML;
        }

        let attr = {};
        for (let aname of node.getAttributeNames()) {
            attr[aname] = node.getAttribute(aname);
        }

        return this.tagToReactBuilder(node.tagName, attr, inner)
    }
    
    fromJSON(payload) {
        let childrenRaw = 'children' in payload ? payload['children'] : [];
        let inner = childrenRaw.length === 0 ? payload.innerText : childrenRaw.map(this.fromJSON.bind(this));
        const attr = 'attr' in payload ? payload.attr : {};
        switch (payload['tag']) {
        case 'ol':
            return (
                <div className="puzzleClueList">
                    {payload.title && <b>{payload.title}</b>}
                    <ol  {...attr}>
                        {payload.items.map(
                            x => Array.isArray(x) ? <li value={x[0]}>{x[1]}</li> : <li>{x}</li>
                        )}
                    </ol>
                </div>
            );
        case 'ul':
            return (
                <div className="puzzleClueList">
                    {payload.title && <b>{payload.title}</b>}
                    <ul {...attr}>
                        {payload.items.map(x => <li>{x}</li>)}
                    </ul>
                </div>
            );
        case 'crossword':
            return (
                <CrosswordGrid rows={payload.grid} {...attr} />
            );
                
        default:
            return this.tagToReactBuilder(payload['tag'], attr, inner);
        }
    }
    
    jsonToMarkup(payload, imgs) {
        let childrenRaw = 'children' in payload ? payload['children'] : [];
        let inner = childrenRaw.length === 0 ? payload.innerText : childrenRaw.map(x => this.jsonToMarkup(x, imgs)).join('\n');
        const attr = 'attr' in payload ? payload.attr : {};

        switch (payload['tag']) {
        case 'b':
            return '<b>' + inner + '</b>';
        case 'br':
            return '<br />';
        case 'flex':
        case 'div':
            return '<div>' + inner + '</div>';
        case 'table':
            return '<table>' + inner + '</table>';
        case 'row':
            return '<tr>' + inner + '</tr>';
        case 'cell':
            return '<td>' + inner + '</td>';
        case 'block':
            return '<td style="background-color:black">' + inner + '</td>';
        case 'img':
            const imgSrc = attr.src;

            /*
            // we don't support copying images to buffer yet. Since clipboard api doesn't support
            // copying multiple clipboard items yet.
            fetch(imgSrc)
                .then(x => x.blob())
                .then(x => {
                    imgs[imgSrc] = new window.ClipboardItem({[x.type]: x});
                })

            // purposely returning the same open and "close" tab for post-processing
            //return '<img>' + imgSrc + '<img>';
            */
            return '[puzzle image not copied]';
        case 'span':
            return '<span>' + inner + '</span>';
        case 'ol':
            let i = 0;
            return (
                '<div>'
                    + (payload.title && '<b>' + payload.title + '</b>')
                    + '<table>'
                    + payload.items.map(
                          x => Array.isArray(x) ?
                              '<tr><td>' + (i = x[0]) + '</td><td>' + x[1] + '</td><td></td></tr>' :
                              '<tr><td>' + (i += 1) + '</td><td>' + x + '</td><td></td></tr>'
                      ).join('\n')
                    + '</table>'
                + '</div>'
            );
        case 'ul':
            return (
                '<div>'
                    + (payload.title && '<b>' + payload.title + '</b>')
                    + '<table>'
                    + payload.items.map(x => '<tr><td>*</td><td>' + x + '</td><td></td></tr>').join('\n')
                    + '</table>'
                + '</div>'
            );
        case 'crossword':
            return crosswordGridMarkup(payload.grid);

        default:
            return '';
        }
    }
    
    jsonToClipboardItem(puzzleData) {
	let htmlBlob = b => {
            return new window.ClipboardItem(
		{'text/html': new window.Blob([b], {type: 'text/html'})}
            );
	};

	let imgs = {};
	let htmlContent = '<h1>' + puzzleData.name + '</h1>' + this.jsonToMarkup(puzzleData.puzzle, imgs);
	return htmlContent.split('<img>').map(b => b in imgs ? imgs[b] : htmlBlob(b));
    }
}
