import React from 'react';
import { Container } from 'react-bootstrap';
import {
    Alert,
    Button,
    Card,
    Form,
    InputGroup,
} from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import SiteURL from '../common/SiteURL.js';
import { fetchLoginStatus, postWithToken } from '../common/SecurityUtils.js';

export default function AccountLogin({next}) {
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [message, setMessage] = React.useState('');
    const [success, setSuccess] = React.useState(null);
    const [validated, setValidated] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [confirmEmail, setConfirmEmail] = React.useState('');

    const usernameRef = React.useRef();
    const emailRef = React.useRef();
    const confirmEmailRef = React.useRef();
    
    const navigate = useNavigate();

    const handleSubmit = React.useCallback((e) => {
	if (email !== confirmEmail) {
	    setMessage('Please double check your email address');
	    setSuccess(false);
	    setIsLoaded(true);

	    emailRef.current.setCustomValidity('Emails are not the same!');
	    confirmEmailRef.current.setCustomValidity('Emails are not the same!');
	    setValidated(true);

            e.preventDefault();
            e.stopPropagation();
	    return;
	}

	setValidated(true);
        setIsLoaded(false);
        const data = {
	    'username': username,
            'email': email,
        };

        postWithToken('/account/signup/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
		    setSuccess(res.success);

		    if (res.username_used) {
			usernameRef.current.setCustomValidity('Username Unavailable');
		    }
		    if (res.email_used) {
			emailRef.current.setCustomValidity('Email Unavailable');
		    }
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
	    );
    }, [username, email, confirmEmail]);

    const onUsernameChange = (e) => {
	usernameRef.current.setCustomValidity('');
	setUsername(e.target.value);
	setValidated(false);
    }
    
    const onEmailChange = (e) => {
	emailRef.current.setCustomValidity('');
	confirmEmailRef.current.setCustomValidity('');
	setEmail(e.target.value);
	setValidated(false);
    };

    const onConfirmEmailChange = (e) => {
	emailRef.current.setCustomValidity('');
	confirmEmailRef.current.setCustomValidity('');
	setConfirmEmail(e.target.value);
	setValidated(false);
    };

    return (
	<div>
	    {message &&
	     <Alert variant={success ? 'success' : 'danger'}>
		 {message}
	     </Alert>}		
	    <Form noValidate validated={validated} disabled={!isLoaded}>
		<InputGroup className="mb-3" controlId="formUsername">
	            <InputGroup.Text>Username</InputGroup.Text>
	            <Form.Control
			required
			ref={usernameRef}
			type="text"
			value={username}
			onChange={onUsernameChange}
		    />
		</InputGroup>
		<InputGroup className="mb-3" controlId="formEmail">
	            <InputGroup.Text>Email</InputGroup.Text>
	            <Form.Control
			ref={emailRef}
			type="email"
			value={email}
			onChange={onEmailChange}
		    />
		</InputGroup>
		<InputGroup className="mb-3" controlId="formConfirmEmail">
	            <InputGroup.Text>Retype Email</InputGroup.Text>
	            <Form.Control
			ref={confirmEmailRef}
			type="email"
			value={confirmEmail}
			onChange={onConfirmEmailChange}
		    />
		</InputGroup>
		<Button
		    variant="primary"
		    onClick={(e) => handleSubmit(e)}>
		    Verify Email
		</Button>
	    </Form>
	</div>
    );
}
