import React from 'react';
import { Alert, Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';

import Loading from '../common/Loading.js';
import SellerOnboarding from './SellerOnboarding.js';

export default function EventTicketView({eventID, ticketH}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    React.useEffect(() => {
        setIsLoaded(true);
    }, []);

    if (!isLoaded) {
        return <Loading />;
    }

    return (
        <Container>
            <Card className="mb-2">
		<Card.Header><h3>Becoming an Event Host</h3></Card.Header>
                <Card.Body>
		    <Card.Text>
			<p>
			    If you are hosting an event in the Bay Area and would like an
			    alternative to Eventbrite, look no further!
			</p>
			<p>
			    We offer end-to-end ticketing needs - from event listing, online
			    ticketing, sales monitoring, ticket admission.
			</p>
			<p>
			    Contact <a href="mailto:cocoa@corgillogical.com" target="_blank">cocoa@corgillogical.com</a> to learn more!
			</p>
		    </Card.Text>
                </Card.Body>
            </Card>


	    
	    <Card>
		<Card.Header><h3>Event Host Agreement</h3></Card.Header>
		<Card.Body className="privacyPolicyContainer">
		    <p>(Last updated: January 2, 2025)</p>
		    <p>
			This Agreement describes the terms and conditions for
			leveraging our event-hosting capabilities as an event host.

			As an event host, you are agreeing to the terms and conditions
			of this Agreement,
			and to our <a href="/privacy/">Privacy Policy</a>
		    </p>

		    <h4>Eligibility</h4>
		    <p>
			To use our event-hosting capabilities, you must
			(i) agree to this Agreement,
			(ii) you are located in the United States
			(iii) comply with all applicable local and federal law.
		    </p>
		    
		    <p>
			<b>Disclosure Authorization. </b> We may share basic registration information with our Payment Processor (Paypal) in order to
			facilitate online payment processing. 
		    </p>
		    
		    
		    <p>
			<b>Failure to Provide. </b> In order to receive payouts from event proceeding, you must provide full and accurate registration data.
			We reserve the right to suspend your access to event-hosting capabiities and withhold any payments otherwise due to you.
		    </p>

		    <p>
			<b>Prohibited Individuals.</b> By registering as an Event Host, you warrant that you are not a person or entity or under the control of or affiliated with a person or entity that
			<ol>
			    <li> appears on the U.S. Office of Foreign Assets Control's Specially Designated Nationals List, Foreign Sanctions Evaders List or Palestinian Legislative Council List;</li>
			    <li> appears on the U.S. Department of State's Terrorist Exclusion List;</li>
			    <li> appears on the Bureau of Industry and Security's Denied Persons List;</li>
			    <li> appears on the Consolidated List of Targets published by the U.K. HM Treasury; </li>
			    <li> appears on the Consolidated List published by the A.U. Department of Foreign Affairs and Trade; or </li>
			    <li> is subject to sanctions in any other country </li>
			</ol>
		    </p>

		    {/* PROHIBITED EVENTS */}
		    <h4>Prohibited Uses and Events</h4>
		    <p>
			You may only host and sell tickets for physically attendable activities, and such activites must not
			violate any local, state, provincial, and federal law, rules or regulations.
			
			You agree not to use the Platform for any of the following purposes:
			<ol>
			    <li>
				<b>Illegal Activities:</b>
				Promoting, facilitating, or participating in any illegal activity, including but not limited to:
				<ol>
				    <li>Selling tickets to events that violate local, state, or federal laws.</li>
				    <li>Fraudulent activities, such as selling counterfeit tickets or engaging in ticket scalping beyond legal limits.</li>
				    <li>Infringing on intellectual property rights, such as selling tickets to unauthorized screenings or performances.</li>
				</ol>
			    </li>
			    <li>
				<b>Harmful Content:</b>
				Posting, transmitting, or distributing any content that is:
				<ol>
				    <li>Defamatory, libelous, or slanderous.</li>
				    <li>Obscene, pornographic, or sexually explicit.</li>
				    <li>Hate speech, or promotes discrimination based on race, ethnicity, religion, gender, sexual orientation, disability, or other protected characteristics.</li>
				    <li>Violent, threatening, or promotes self-harm.</li>
				</ol>
			    </li>
			    <li>
				<b>Misrepresentation and Fraud:</b>
				<ol>
				    <li>Creating misleading event descriptions or providing false information about events.</li>
				    <li>Impersonating another person or entity.</li>
				    <li>Using false or misleading contact information.</li>
				    <li>Manipulating ticket sales or reviews.</li>
				</ol>
			    </li>
			    <li>
				<b>Spam and Unsolicited Communications:</b>
				<ol>
				    <li>Sending unsolicited bulk emails or other forms of spam.</li>
				    <li>Using the Platform for unauthorized advertising or marketing.</li>
				</ol>
			    </li>
			    <li>
				<b>Security Violations:</b>
				<ol>
				    <li>Attempting to gain unauthorized access to the Platform or other {"users'"} accounts.</li>
				    <li>Distributing viruses, malware, or other harmful software.</li>
				    <li>Interfering with the proper functioning of the Platform.</li>
				</ol>
			    </li>
			    <li>
				<b>Ticket Scalping:</b>
				Reselling tickets at prices significantly above face value in jurisdictions where such practices are illegal. You are responsible for understanding the laws in your area.
			    </li>
			    <li>
				<b>Activities that endanger public health or safety:</b>
				Selling tickets to events that demonstrably violate public health orders or pose a significant risk to attendees.
			    </li>
			</ol>
		    </p>
			

		    {/* HOST RESPONSIBILIES */}
		    <h4>Event Host Responsibilities</h4>
		    <p>
			In addition to the general prohibitions above, event hosts are responsible for:
			<ol>
			    <li>
				<b>Accurate Event Information:</b>
				Providing accurate and complete information about their events, including date, time, location, description, and ticket pricing.
			    </li>
			    <li>
				<b>Compliance with Laws:</b>
				Ensuring their events comply with all applicable laws and regulations, including licensing, permits, and safety requirements.
			    </li>
			    <li>
				<b>Refund Policies:</b>
				Clearly establishing, communicating, and respecting your refund policies to ticket buyers.
			    </li>
			    <li>
				<b>Providing support and Handling Disputes:</b>
				Providing an event host contact email address, and addressing ticket buyer inquiries and resolving disputes in a timely and professional manner.
			    </li>
			    <li>
				<b>Content Ownership:</b>
				You are responsible for the content you upload to the platform and warrant that you have the necessary rights to use and distribute it.
				We reserve the rights to remove any imagery that may violate laws.
			    </li>
			</ol>
		    </p>


		    {/* RIGHT TO TERMINATE / ENFORCEMENT */}
		    <h4>Enforcement and Right to Terminate</h4>
		    <p>
			We reserve the right to investigate any suspected violations of this Agreement.
			We may take any action we deem appropriate, including but not limited to:
			<ol>
			    <li>Removing content in violation</li>
			    <li>Suspending or terminating events in violation</li>
			    <li>Suspending or terminating host accounts in violation</li>
			    <li>Reporting illegal activity to law enforcement</li>
			</ol>
		    </p>

		    
		    
		    <h4>Contact Us</h4>
		    <p>If you have any questions about this Agreement, You can contact us:</p>
		    <ul>
			<li>By email: cocoa@corgillogical.com</li>
		    </ul>
		</Card.Body>
	    </Card>
	    <SellerOnboarding />
        </Container>
    );
}
