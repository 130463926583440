import React from 'react';
import { CardGroup } from 'react-bootstrap';

import ShowPerformerCard from './ShowPerformerCard.js';
import ShowPerformanceCard from './ShowPerformanceCard.js';
import ShowPerformanceSummaryCard from './ShowPerformanceSummaryCard.js';
import ShowProgramEditor from './ShowProgramEditor.js';

export default function ShowDetailView({show, program}) {
    const [toggle, setToggle] = React.useState(0);
    const onEdit = () => {
	setToggle(toggle + 1);
    };

    return (
	<div>
	    <ShowPerformanceSummaryCard show={show} />
	    <CardGroup>
		<ShowPerformerCard onEdit={onEdit} />
		{program && <ShowProgramEditor program={program} onEdit={onEdit} />}
	    </CardGroup>
	    <ShowPerformanceCard show={show} toggle={toggle} key={toggle} />
	</div>
    );
}
