import React from 'react';
import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Modal
} from 'react-bootstrap';

import { validateEmail } from '../common/Utils.js';
import Loading from '../common/Loading.js';
import { fetchLoginStatus, getWithData, postWithToken } from '../common/SecurityUtils.js';
import LoggedOutView from '../common/LoggedOutView.js';
import SiteURL from '../common/SiteURL.js';

function MerchantStatus({status}) {
    switch (status) {
    case 'DB': return <Badge bg="dark">Disabled</Badge>;
    case 'PD': return <Badge bg="secondary">Pending</Badge>;
    case 'AT': return <Badge bg="success">Active</Badge>;
    default: return <Badge bg="danger">Unknown</Badge>;
    }
}
function DisconnectPayPalConfirmation({onHide, onConfirm, ...props}) {
    return (
	<Modal {...props} size="lg" onHide={onHide} centered>
	    <Modal.Header closeButton>
		<Modal.Title>
		    Confirm Disconnection
		</Modal.Title>
	    </Modal.Header>
	    <Modal.Body>
		<p>
		    Disconnecting your PayPal account will prevent you from
		    receiving tickets proceeds. Do you wish to continue?
		</p>
	    </Modal.Body>
	    <Modal.Footer>
		<Button onClick={onConfirm} variant="primary">Disconnect</Button>
		<Button onClick={onHide} variant="secondary">Close</Button>
	    </Modal.Footer>
	</Modal>
    );
}

export default function SellerOnboarding() {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [validated, setValidated] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [loggedIn, setIsLoggedIn] = React.useState(false);
    const [actionURL, setActionURL] = React.useState(null);
    const [sellerID, setSellerID] = React.useState(null);
    
    const [confirmDisconnect, setConfirmDisconnect] = React.useState(false);
    const [canDisconnect, setCanDisconnect] = React.useState(false);
    
    const [name, setName] = React.useState(null);
    const [consent, setConsent] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [status, setStatus] = React.useState(null);

    const consentRef = React.useRef();

    // Load logged in data once
    React.useEffect(() => {
        setIsLoaded(false);
        fetchLoginStatus((loggedIn) => {
            setIsLoggedIn(loggedIn);
	    setIsLoaded(true);
        });
    }, []);

    // Fetch once
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/event/fetch_host/')
            .then(res => res.json())
            .then(
                (payload) => {
		    setSuccess(payload.success)
		    if (!payload.success) {
			setMessage(payload.msg);
		    } else {
			setName(payload.name);
			setEmail(payload.email);
			setStatus(payload.status);
			setConsent(payload.consent);
			setSellerID(payload.sellerid);
			setCanDisconnect(!!payload.sellerid);
			setActionURL(payload.action_link);
		    }
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                }
            )
    }, [loggedIn]);

    const handleEdit = React.useCallback((e) => {
        const form = e.currentTarget;
	setValidated(false);
        let isValid = form.checkValidity();
	if (!consent) {
	    const mustConsentMsg = 'You must consent to the Event Host Agreement';
	    consentRef.current.setCustomValidity(mustConsentMsg);
            setMessage(mustConsentMsg);
	    isValid = false;
	}
	
        setValidated(true);
        if (!isValid) {
	    e.preventDefault();
            e.stopPropagation();
            return;
        }
	
        setIsLoaded(false);
        const data = {
            'name': name,
            'email': email.trim(),
            'consent': !!consent,
        };

        postWithToken('/event/edit_host/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
		    if (res.action_link) {
			setActionURL(res.action_link);
		    }
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
            );
    }, [name, email, consent]);

    const handleDisconnect = React.useCallback((e) => {
        setIsLoaded(false);
        postWithToken('/event/disconnect_seller/', {})
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
		    setCanDisconnect(false);
		    setConfirmDisconnect(false);
		    setStatus(res.status);
		    setSellerID(res.sellerid);
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
            );
    }, [name, email, consent]);

    const handleVerify = React.useCallback((newonly = false) => (e) => {
        setIsLoaded(false);
        postWithToken('/event/verify_seller/', {newonly: newonly})
            .then(res => res.json())
            .then(
                (res) => {
		    if (res.redirect) {
			window.location.href = res.redirect;
			return;
		    }
		    setSellerID(res.sellerid);
		    setCanDisconnect(!!res.sellerid);
                    setMessage(res.msg);
		    setStatus(res.status);
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
            );
    }, [actionURL]);

    if (!isLoaded) {
        return <Loading />;
    }

    if (!loggedIn) {
        let url = new SiteURL('host/')
            .event()
            .getRelative();
	return (
	    <Card>
		<Card.Body>
		    <LoggedOutView next={url}>
			<div>
			    You are not currently logged in.
			    <br /><br />
			    Log in to see if you are ready to host an event!
			</div>
		    </LoggedOutView>
		</Card.Body>
	    </Card>
	);
    }

    if (!success) {
	return (
	    <Card className="mt-3">
		<Card.Body>
		    {message && <Alert>{message}</Alert>}
		</Card.Body>
	    </Card>
	);
    }

    const renderVerifyButton = () => {
	if (sellerID && status === 'AT') {
	    return null;
	}
	return (
	    <Button
		disabled={!consent}
		className="mx-2"
		variant="secondary"
		onClick={handleVerify()}>
		Verify PayPal Account
	    </Button>
	);
    };
    
    const renderDisconnectButton = () => {
	if (!canDisconnect) {
	    return null;
	}
	return (
	    <Button
		className="mx-2"
		variant="danger"
		onClick={() => setConfirmDisconnect(true)}>
		Disconnect PayPal
	    </Button>
	);
    };

    const renderConnectButton = () => {
	if (sellerID && status === 'AT') {
	    return null;
	}

	if (true || !actionURL) {
	    // Need a new partner referral
	    return (
		<Button
		    disabled={!consent}
		    className="mx-2"
		    variant="primary"
		    onClick={handleVerify(true)}>
		    Connect New PayPalAccount
		</Button>
	    );
	}
	return (
	    <Button
		disabled={!consent}
		className="mx-2"
		variant="primary"
		href={actionURL}>
		Connect New PayPal Account
	    </Button>
	);
    };

    const renderPayPalStatus = () => {
	let message = '';
	if (sellerID && status === 'AT') {
	    // PayPal Account connected and active.
	    return (
		<Alert variant="success">
		    You PayPal account is connected and you can receive
		    payments for your event ticket proceedings.
		    <br />
		    If you would like to switch to a different PayPal account or
		    would simply wish to disconnect the account, click the
		    "disconnect PayPal" button below.
		</Alert>
	    );
	}

	if (sellerID) {
	    // PayPayl merchant id known, but not active.	    
	    return (
		<Alert variant="info">
		    You PayPal account is connected but you are not yet able to
		    receive payments for your event ticket proceedings.
		    <br />
		    Click "Verify PayPal" to verify account status and reenable payment.
		</Alert>
	    );
	}

	
	return (
	    <Alert variant="secondary">
		If you have previously connected a PayPal account, you may use the
		"Verify PayPal" button to verify account status and reenable payment.
		<br />
		You may also use the "Connect New PayPal Account" to connect a different account.
	    </Alert>
	);
    };
    
    return (
	<>
	<Card className="my-3">	    
	    <Card.Header>
		Even Host Info
	    </Card.Header>
	    <Card.Body>
		{message && <Alert>{message}</Alert>}
		<Form noValidate validated={validated}>
		    <InputGroup size="sm" className="mb-3" controlId="formName">
			<InputGroup.Text>Name</InputGroup.Text>
			<Form.Control
			    readonly
			    value={name}
			    type="text"
			    placeholder="Your Name"
			/>
		    </InputGroup>
		    <InputGroup size="sm" className="mb-3" controlId="formEmail">
			<InputGroup.Text>Email</InputGroup.Text>
			<Form.Control
			    readonly
			    value={email}
			    type="email"
			    placeholder="Enter email"
			/>
		    </InputGroup>
		    <InputGroup size="sm" controlId="formConsent">
			<Form.Check
			    ref={consentRef}
			    type="switch"
			    checked={consent}
			    label="I Consent to the Event Host Agreement"
			    onChange={e => {consentRef.current.setCustomValidity(''); setConsent(!consent);}}
			/>
		    </InputGroup>
		</Form>
	    </Card.Body>
	    <Card.Footer>
		{renderPayPalStatus()}
		{renderConnectButton()}
		{renderVerifyButton()}
		{renderDisconnectButton()}
	    </Card.Footer>
	</Card>
	    <DisconnectPayPalConfirmation
		show={confirmDisconnect}
		onHide={() => setConfirmDisconnect(false)}
		onConfirm={() => handleDisconnect()}
	    />
	</>
    );
}
