import React from 'react';
import {
    Alert,
    Button,
    Card,
    Form,
    InputGroup
} from 'react-bootstrap';

import Loading from '../common/Loading.js';
import { ShowProgramStatus } from './ShowProgramComponents.js';
import { getWithData, postWithToken } from '../common/SecurityUtils.js';

export default function ShowProgramEditor({program, onEdit}) {
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [message, setMessage] = React.useState('');
    const [validated, setValidated] = React.useState(false);
    const [success, setSuccess] = React.useState(null);
    const [slots, setSlots] = React.useState({});
    
    // States for editable program fields
    const [order, setOrder] = React.useState(program.order);
    const [name, setName] = React.useState(program.name);
    const [backing, setBacking] = React.useState(program.backing);
    const [notes, setNotes] = React.useState(program.notes);
    const [style, setStyle] = React.useState(program.style);

    // Load available slots
    React.useEffect(() => {
        setIsLoaded(false);
	let rdata = {
	    program: program.program_id,
	    show: program.performance.show_id,
	};
        getWithData('/show/fetch_available_slots/', rdata)
            .then(res => res.json())
            .then(
                (payload) => {
		    setSlots(payload.slots);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                }
            )
    }, []);
    
    const handleEdit = React.useCallback((e) => {
        const form = e.currentTarget;
	setValidated(false);
        let isValid = form.checkValidity();
        setValidated(true);
        if (!isValid) {
	    e.preventDefault();
            e.stopPropagation();
            return;
        }

        setIsLoaded(false);
        const data = {
	    'program': program.program_id,
            'name': name,
            'backing': backing,
            'notes': notes,
	    'order': order,
	    'style': style,
        };

        postWithToken('/show/edit_program/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
		    setSuccess(res.success);
                    setIsLoaded(true);
		    if (onEdit) {
			onEdit();
		    }
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
	    );
    }, [name, backing, notes, style, order, onEdit, program.program_id]);

    const renderStyleSelection = () => {
        return (
            <InputGroup size="sm" className="mb-3" controlId="formType">
                <InputGroup.Text>Type</InputGroup.Text>
                <Form.Select
                    aria-label="Performance Type"
		    value={style}
                    onChange={e => {
                        setStyle(e.target.value);
                    }}
                >
                    <option key={'style-option-solo'} value="SO">Solo</option>
		    <option key={'style-option-duet'} value="DU">Duet</option>
		    <option key={'style-option-band'} value="BA">Band</option>
		    <option key={'style-option-inst'} value="IN">Instrumental</option>
		    <option key={'style-option-other'} value="OT">Other (specify in notes)</option>
                </Form.Select>
            </InputGroup>
	);
    };
    
    const renderOrderSelection = () => {
	let maxOrder = program.performance.max_programs ?? 28;
        return (
            <InputGroup size="sm" className="mb-3" controlId="formOrder">
                <InputGroup.Text>Order</InputGroup.Text>
                <Form.Select
                    aria-label="Performance Order"
		    value={order}
                    onChange={e => {
                        setOrder(e.target.value);
                    }}
                >
                    {Array.from(Array(maxOrder + 1), (e, i) => i).filter(x => x in slots).map(i => {
			return <option key={'num-option-' + i} value={i}>{i === 0 ? 'Undecided' : i}</option>;
                    })}
                </Form.Select>
            </InputGroup>
        );
    };

    return (
	<Card>
	    <Card.Header>
		{name ? name : '<Unnamed Program>'}
		<div className="headerCorner">
		    <ShowProgramStatus status={program.status} />
		</div>
	    </Card.Header>
	    <Card.Body>
		{message &&
		 <Alert variant={success ? 'success' : 'danger'}>
		     {message}
		 </Alert>}		
		<Form noValidate validated={validated}>
		    <InputGroup size="sm" className="mb-3" controlId="formName">
			<InputGroup.Text>Song Name</InputGroup.Text>
			<Form.Control
			    required
			    value={name}
			    type="text"
			    placeholder="Program Name"
			    onChange={e => setName(e.target.value)}
			/>
		    </InputGroup>
		    <InputGroup size="sm" controlId="formNotes">
			<InputGroup.Text>Notes</InputGroup.Text>
			<Form.Control
			    as="textarea"
			    row={5}
			    value={notes}
			    placeholder="Other notes (e.g. playing guitar)"
			    onChange={e => setNotes(e.target.value)}
			/>
		    </InputGroup>
		    <Form.Text className="mb-3" muted>
			e.g. Instrument needs, number of people, ...
		    </Form.Text>
		    
		    {renderOrderSelection()}
		    {renderStyleSelection()}
		    
		    <Form.Text className="mb-3" muted>
			Please upload your backing track to the google drive specified in the instructions above <b>no later than the Friday before the show</b>.
		    </Form.Text>
		</Form>
	    </Card.Body>
	    <Card.Footer>
		<Button
		    disabled={!isLoaded}
		    variant="primary"
		    onClick={(e) => handleEdit(e)}>
		    Save
		</Button>
	    </Card.Footer>
	</Card>
    );
}
