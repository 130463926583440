import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSearchParams } from 'react-router-dom';

import CommonNavbar from '../common/CommonNavbar.js';

function ShopLinkContainer() {
    let [searchParams] = useSearchParams();
    let tab = searchParams.get('tab') ?? '';
    if (tab !== 'events') {
	return null;
    }
    return (
	<>
	    <LinkContainer to="/event/host/">
		<Nav.Link>Become an Event Host</Nav.Link>
	    </LinkContainer>
	</>
    );
}

export default function ShopHeader() {
    return (
        <CommonNavbar className="sokobanNavbar" title="Shop">
            <Nav>
		<ShopLinkContainer />
            </Nav>
        </CommonNavbar>
    );
}
