import React from 'react';
import { Container } from 'react-bootstrap';
import {
    Alert,
    Button,
    Card,
    Form,
    InputGroup,
} from 'react-bootstrap';
import { Google } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import SiteURL from '../common/SiteURL.js';
import { postWithToken } from '../common/SecurityUtils.js';

export default function AccountLogin({next}) {
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [message, setMessage] = React.useState('');
    const [success, setSuccess] = React.useState(null);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    
    const navigate = useNavigate();
    const handleSubmit = React.useCallback((e) => {
        setIsLoaded(false);
        const data = {
	    'username': username,
            'password': password,
        };

        postWithToken('/account/login/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
		    setSuccess(res.success);
		    if (res.success && next && SiteURL.isRelative(next)) {
			navigate(next);
		    }
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
	    );
    }, [username, password]);

    return (
	<div>
	    <div>
		<a href={'/oauth/login/google-oauth2/?next=' + next}>
                    <Google /> Login with Google
		</a>
	    </div>
	    <div className="my-4">
		or
	    </div>
	    <div>
		Login with username
	    </div>
	    {message &&
	     <Alert variant={success ? 'success' : 'danger'}>
		 {message}
	     </Alert>}		
	    <Form noValidate disabled={!isLoaded}>
		<InputGroup className="mb-3" controlId="formUsername">
	            <InputGroup.Text>Username</InputGroup.Text>
	            <Form.Control
			type="text"
			value={username}
			onChange={e => setUsername(e.target.value)}
		    />
		</InputGroup>
		<InputGroup className="mb-3" controlId="formPassword">
	            <InputGroup.Text>Password</InputGroup.Text>
	            <Form.Control
			type="password"
			value={password}
			onChange={e => setPassword(e.target.value)}
		    />
		</InputGroup>
		<Button
		    variant="primary"
		    onClick={(e) => handleSubmit(e)}>
		    Sign In
		</Button>
	    </Form>
	</div>
    );
}
