import './Corgillogical.css';
import './CorgillogicalPuzzle.css';

import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import CorgillogicalCopyPuzzle from './CorgillogicalCopyPuzzle.js';
import CorgillogicalHint from './CorgillogicalHint.js';
import ConfigurableMarkup from '../common/ConfigurableMarkup.js';

function getSolveStatusText(numSolutions, solved) {
    if (numSolutions === 0) {
        return 'No one has solved this puzzle yet';
    }

    if (numSolutions === 1) {
        if (solved) {
            return 'You are the only person to solve this puzzle!';
        }
        return 'One person solved this puzzle';
    }
    return numSolutions + ' people solved this puzzle' + (solved ? ', including you!' : '');
}

export default function CorgillogicalPuzzle({
    puzzleData,
}) {
    const solveToolTip = getSolveStatusText( puzzleData.numSolves ?? 0, puzzleData.solved);
    return (
        <>
            <OverlayTrigger placement="top" overlay={
                <Tooltip>{solveToolTip}</Tooltip>
            }>
                <div className={'puzzleTitle' + (puzzleData.solved ? ' solvedPuzzle' : '')}>
                    {puzzleData.name}
                </div>
            </OverlayTrigger>

            <div className="puzzleTopFloatContainer">
                <CorgillogicalCopyPuzzle puzzleData={puzzleData} />

                <CorgillogicalHint puzzleID={puzzleData.puzzle_id} numHints={Object.entries(puzzleData.hints).length} />
            </div>

            <div className="puzzleBody">
                {puzzleData.puzzle_type === 'html' && new ConfigurableMarkup().fromJSON(puzzleData.puzzle)}
            </div>
        </>            
    );
}
