import React from 'react';
import { Card, CardGroup, Col, Row } from 'react-bootstrap';

import EventTicketReservationForm from './EventTicketReservationForm.js';

function springConcert05072023(data, showForm) {
    return (
        <div style={{textAlign:'left'}}>
            <h1>
                <b>{'Live Spring Concert'}</b>
            </h1>
            <CardGroup style={{marginBottom:'8px'}}>
                <Card>
                    <Card.Header><b>Date</b></Card.Header>
                    <Card.Body>Sunday, May 7th 2023 7:00pm</Card.Body>
                </Card>
                <Card>
                    <Card.Header><b>Location</b></Card.Header>
                    <Card.Body>
                        Vinum Japanese Fusion & Bar
                        <br />
                        <small className="text-muted">
                            2025 Newpark Mall Rd, Newark, CA 94560
                        </small>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header><b>Ticket Price</b></Card.Header>
                    <Card.Body>
                        USD $20
                        <br />
                        <small className="text-muted">
                            (includes 20% off on food & drink @ Vinum)
                        </small>
                    </Card.Body> 
                </Card>
            </CardGroup>

            <Card style={{marginBottom:'8px'}}>
                <Card.Img variant="top" src="/static/spring_concert_57_cover.jpg" />
                <Card.Body>
                <Card.Text>
                    <b>About this Event</b>
                    <br />
                    Join us for a lively spring concert with good friends, good food, good wine, and good music! 
                </Card.Text>
                <Card.Text>
                    Our songs explore topics such as the (in)ability to stay present, 
                    social media identity, finding acceptance for rapid changes, sweet
                    love, poignant loss, and a collaborated song about a special friend{"'"}s life story.
                </Card.Text>
                <Card.Text>
                    <b>About the 20% Food & Drink Discount @ Vinum</b>
                    <br />
                    The ticket price includes a 20% off food & drink at Vinum on the day of the event.
                    Note that <b>the last call for food & drink orders is 6:30pm</b>.
                    Please plan to arrive early if you intend to leverage this special discount!
                </Card.Text>
                <Card.Text>
                    <b>Band members:</b>
                    <ul>
                        <li> Xiaotian - vocal </li>
                        <li> Isaac - keys </li>
                        <li> Willy - drums </li>
                        <li> Xiao Hang - bass </li>
                        <li> Xiaoye - vocal </li>
                        <li> Jingjing - vocal </li>
                    </ul>
                </Card.Text>
                <Card.Text>
                    <b>Link to this event page</b>: 
                    {' '}
                    <a href="https://www.corgillogical.com/event/?event_id=springconcert">
                        {'https://www.corgillogical.com/event/?event_id=springconcert'}
                    </a>
                </Card.Text>
                <Card.Text>
                    <b>Location</b>
                    <br />
                    Vinum Japanese Fusion & Bar
                    <br />
                    2025 Newpark Mall Rd, Newark, CA 94560
                </Card.Text>
                
                <Card.Text>
                    <b>Contact</b>
                    <br />
                    Please contact corgillogical@gmail.com if you have any questions!
                </Card.Text>
                
                <img
                    src="/static/spring_concert_57_program.jpg"
                    style={{display: 'block', maxWidth: '640px', marginBottom: '24px', width: '100%'}}
                />

                <Card.Text>
                    <b>How to Purchase Tickets</b>
                    <br />
                    <ol>
                        <li>Purcahse a ticket using the form below. Be sure to double check your email so you can receive your eTicket.</li>
                        <li>After completing the payment, you will get a link (and QR code) to your eTicket. The same link will also be sent to your email.</li>
                        <li>On the day of the concert, have the ticket QR code ready to be admitted!</li>
                    </ol>
                </Card.Text>
                </Card.Body>
            </Card>

            { showForm && <EventTicketReservationForm eventData={data} eventID={data.event_id} canReserve={data.can_reserve} /> }

            <br />
        </div>
    );
}

function winterSonata3(data, showForm) {
    return (
        <div style={{textAlign:'left'}}>
            <h1>
                <b>{'\u51ac\u5b63\u604b\u6b4c WINTER SONATA 3.0'}</b>
            </h1>
            <CardGroup style={{marginBottom:'8px'}}>
                <Card>
                    <Card.Header><b>Date</b></Card.Header>
                    <Card.Body>Sunday, December 4th 2022 7:30pm</Card.Body>
                </Card>
                <Card>
                    <Card.Header><b>Location</b></Card.Header>
                    <Card.Body>The Hub room@ Crosspoint Church of Silicon Valley</Card.Body>
                </Card>
                <Card>
                    <Card.Header><b>Ticket Price</b></Card.Header>
                    <Card.Body>USD $20</Card.Body>
                </Card>
            </CardGroup>

            <Card style={{marginBottom:'8px'}}>
                <Card.Img variant="top" src="/static/winter_sonata_cover.jpg" />
                <Card.Body>
                <Card.Text>
                    <b>About this Event</b>
                    <br />
                    {"\u751c\u751c\u8001\u5e08\u7684\u5b66\u751f\u97f3\u4e50\u4f1a\u3002\u4eca\u5e74\u7684\u97f3\u4e50\u4f1a\u7cbe\u5f69\u5347\u7ea7\uff01\u6211\u4eec\u5c06\u4e8e12\u67084\u65e5\u5728\u8001\u5730\u65b9\u4e3e\u529e\u3002\u5e0c\u671b\u5728\u51ac\u65e5\u91cc\u7528\u6b4c\u58f0\u5e26\u7ed9\u5927\u5bb6\u4e00\u4e1d\u6e29\u6696\u3002"}
                </Card.Text>
                <Card.Text>
                    Regarding safety issues, please follow the guidelines below:
                    <ol>
                        <li> Mask on is required at all time </li>
                        <li> No outside food at the venue </li>
                        <li> No smoke, drugs and marijuana at the venue </li>
                    </ol>
                </Card.Text>
                <Card.Text>
                    <b>Link to this event page</b>: 
                    {' '}
                    <a href="https://www.corgillogical.com/event/?event_id=wintersonata3">
                        {'https://www.corgillogical.com/event/?event_id=wintersonata3'}
                    </a>
                </Card.Text>
                <Card.Text>
                    <b>Location</b>
                    <br />
                    The Hub room@ Crosspoint Church of Silicon Valley
                    <br />
                    658 Gibraltar Ct., Milpitas, CA 95035
                </Card.Text>
                
                <b>Program</b>
                <img
                    src="/static/winter_sonata_program_final.jpg"
                    style={{display: 'block', maxWidth: '640px', marginBottom: '24px', width: '100%'}}
                />

                <Card.Text>
                    <Row>
                        <Col sm={8}>
                            <b>How to Purchase Tickets</b>
                            <br />
                            <ol>
                                <li>Reserve tickets using the form below, and keep/print a copy of the ticket links.</li>
                                <li>Make a Venmo payment to @Tiantian-Wang-4 (or use the QR code here)</li>
                                <li>Once the payment is confirmed, your ticket will be confirmed and activated. You can check if your ticket is confirmed using the ticket links.</li>
                            </ol>
                        </Col>
                        <Col sm={4}>
                            <img src="/static/winter_sonata_venmo.jpg" style={{maxWidth: '128px'}} />
                        </Col>
                    </Row>
                </Card.Text>
                <Card.Text>
                    <b>{"\u611f\u8c22Trio Cut\u52a9\u529b\u5ba3\u4f20"}</b>
                </Card.Text>
                </Card.Body>
            </Card>

            { showForm && <EventTicketReservationForm eventData={data} eventID={data.event_id} canReserve={data.can_reserve} /> }

            <br />
        </div>
    );
}

export const HardcodedEvents = {
    'wintersonata3': winterSonata3,
    'spring': springConcert05072023,
    'springconcert': springConcert05072023,
};
