import './App.css';

import {Routes, Route } from 'react-router-dom';

import AccountAppRouter from './account/AccountAppRouter.js';
import BandChestnuters from './BandChestnuters.js';
import BandFlagdog from './BandFlagdog.js';
import BandPaleFire from './BandPaleFire.js';
import CorgillogicalAppRouter from './corgillogical/CorgillogicalAppRouter.js';
import EmailSubscriptionManager from './EmailSubscriptionManager.js';
import EventAppRouter from './event/EventAppRouter.js';
import HomeAppRouter from './home/HomeAppRouter.js';
import QuizAppRouter from './quiz/QuizAppRouter.js';
import RandomNumberGenerator from './RandomNumberGenerator.js';
import ShopAppRouter from './shop/ShopAppRouter.js';
import ShowAppRouter from './show/ShowAppRouter.js';
import SokobanAppRouter from './sokoban/SokobanAppRouter.js';

export default function App() {
    // This is a url => component mapping. The top level /sokoban route specifies the
    // template being used (Layout component defined above) and the children path
    // specify the detailed component whose content will replace the <Outlet /> element
    // in <Layout />
    return (
        <div className="App">
            <Routes>
	        <Route path="/account/*" element={<AccountAppRouter />} />
                <Route path="/sokoban/*" element={<SokobanAppRouter />} />
                <Route path="/puzzle/*" element={<CorgillogicalAppRouter />} />
                <Route path="/shop/*" element={<ShopAppRouter />} />
    		<Route path="/show/*" element={<ShowAppRouter />} />
                <Route path="/event/*" element={<EventAppRouter />} />
                <Route path="/quiz/*" element={<QuizAppRouter />} />
                <Route path="/email/*" element={<EmailSubscriptionManager />} />
                <Route path="/random/*" element={<RandomNumberGenerator />} />
                <Route path="/palefire/*" element={<BandPaleFire />} />
                <Route path="/chestnuters/*" element={<BandChestnuters />} />
                <Route path="/flatdog/*" element={<BandFlagdog />} />
                <Route path="/*" element={<HomeAppRouter />} />
            </Routes>
        </div>
    );
}
