import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { fetchLoginStatus } from '../common/SecurityUtils.js';
import Loading from '../common/Loading.js';
import LoggedOutView from '../common/LoggedOutView.js';
import SiteURL from '../common/SiteURL.js';
import { getWithData } from '../common/SecurityUtils.js';

import ShowPerformerCard from './ShowPerformerCard.js';
import ShowPerformanceList from './ShowPerformanceList.js';
import ShowDetailView from './ShowDetailView.js';

export default function ShowMyShows() {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [programs, setPrograms] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [adminShow, setAdminShow] = React.useState(null);
    const [loggedIn, setIsLoggedIn] = React.useState(false);

    let [searchParams] = useSearchParams();
    let showID = searchParams.get('s');
    let programID = searchParams.get('p');

    // Load logged in data once
    React.useEffect(() => {
        setIsLoaded(false);
        fetchLoginStatus((loggedIn) => {
            setIsLoggedIn(loggedIn);
	    setIsLoaded(true);
        });
    }, []);

    React.useEffect(() => {
	// only fetch data when we confirmed the viewer is logged in.
	if (!loggedIn) {
	    return
	}
	
        setIsLoaded(false);
	let rdata = {}
	if (showID) {
	    rdata.show = showID;
	}
        getWithData('/show/fetch_my_programs/', rdata)
            .then(res => res.json())
            .then(
                (payload) => {
		    setPrograms(payload.programs);
		    setIsAdmin(payload.is_admin);
		    setAdminShow(payload.show);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                }
            )
    }, [loggedIn, showID]);

    if (!isLoaded) {
        return <Loading />;
    }

    if (!loggedIn) {
        let url = new SiteURL()
            .show()
            .getRelative();
	return (
	    <Card>
		<Card.Body>
		    <LoggedOutView next={url}>
			<div>
			    You are not currently logged in.
			    <br /><br />
			    Log in to view and edit your performances!
			</div>
		    </LoggedOutView>
		</Card.Body>
	    </Card>
	);
    }

    if (programID) {
	let program = programs.filter(p => p.program_id == programID)
	if (program.length === 0) {
	    return <Alert variant="danger">Cannot Find Performance</Alert>;
	}
	program = program[0];
	return <ShowDetailView show={program.performance} program={program} />;
    }

    if (showID && isAdmin) {
	return <ShowDetailView show={adminShow} />;
    }
    
    return (
	<div>
	    <ShowPerformerCard />
	    <br />
	    <ShowPerformanceList programs={programs} />
	</div>
    );
}
